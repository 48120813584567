import React from "react";

const Featuresv2 = () => {
  return (
    <section id="features" className="features_2">
      <div className="wrapper2 container">
        <div className="content2">
          <div className="list">
            <div className="l__header">
              <h2>Pourquoi devrais-je avoir "Mon pain quotidien" ?</h2>
            </div>
            <div className="l_item">
              <div className="l_item_wrap">
                <div className="iconbox"></div>
                <div className="desc">
                  <p>
                   Pour grandir dans la connaissance de la parole de Dieu
                  </p>
                </div>
              </div>
            </div>
            <div className="l_item">
              <div className="l_item_wrap">
                <div className="iconbox"></div>
                <div className="desc">
                  <p>
                   Avoir mes études à un même endroit
                  </p>
                </div>
              </div>
            </div>
            <div className="l_item">
              <div className="l_item_wrap">
                <div className="iconbox"></div>
                <div className="desc">
                  <p>
                   Elle sert de notes : possibilité de faire et enregistrer ses études directement dans l’application.
                  </p>
                </div>
              </div>
            </div>
            <div className="l_item">
              <div className="l_item_wrap">
                <div className="iconbox"></div>
                <div className="desc">
                  <p>
                    Faire mes études bibliques n'importe quand et n'importe où 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="illustration">
            <img
              className="img-fluid"
              src="assets/images/daily.png"
              alt="iphone"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Featuresv2;
