import React  from 'react'

import { ImRoad } from 'react-icons/im'
import { IoIosCall } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'

import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'

const Contact = () => {
  /*const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  })*/
  //const { name, email, phone, message } = formData

  /*const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    if (e.target.name === 'email' && !touched.email)
      setTouched({ ...touched, email: true })
    else if (e.target.name === 'name' && !touched.name)
      setTouched({ ...touched, name: true })
    else if (e.target.name === 'phone' && !touched.phone)
      setTouched({ ...touched, phone: true })
    else if (e.target.name === 'message' && !touched.message)
      setTouched({ ...touched, message: true })
    console.log(touched)
  }

  const submit = (e) => {
    e.preventDefault()
    console.log(formData)
  }*/
  return (
    <section id="contact" className="contact">
      <div className="wrapper">
        <div className="content container">
          <div className="contact_inner">
            <div className="left">
              <div className="item">
                <h2>Let's chat</h2>
                <p>
                Si vous avez une question sur l'application ou sur la méditation n'hésitez pas à nous contacter
                </p>
              </div>
              <div className="item">
                <h2>Trouvez nous</h2>
                <p>
                  <span>
                    <ImRoad />
                  </span>
                  <span>3 All. des Prairies, 59493 Villeneuve-d'Ascq</span>
                </p>
                <p>
                  <span>
                    <IoIosCall />
                  </span>
                  <span>(+33)7 83 88 23 16</span>
                </p>
                <p>
                  <span>
                    <MdEmail />
                  </span>
                  <span>contact@monpainquotidien.com</span>
                </p>
              </div>
              <div className="item">
                <h2>Ne manquez rien</h2>
                <span className="icon">
                  <FaFacebook />
                </span>
                <span className="icon">
                  <FaInstagram className="icon" />
                </span>
                <span className="icon">
                  <FaTwitter className="icon" />
                </span>
              </div>
            </div>
           {/*<div className="right">
              <form onSubmit={(e) => submit(e)}>
                <h2>Contact</h2>
                <span>*Required</span>
                <input
                  type="text"
                  placeholder="Nom*"
                  name="name"
                  value={name}
                  onChange={(e) => handleChange(e)}
                />
                <input
                  type="text"
                  placeholder="Email*"
                  name="email"
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
                <input
                  type="phone"
                  placeholder="Phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => handleChange(e)}
                />
                <textarea
                  rows="4"
                  type="text"
                  placeholder="Message*"
                  name="message"
                  value={message}
                  onChange={(e) => handleChange(e)}
                ></textarea>
                <button type="submit">Envoyer</button>
              </form>
            </div> */} 
            
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
