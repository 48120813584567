import React, { useState } from 'react'

import { AiFillSetting } from 'react-icons/ai'
import { RiGitRepositoryPrivateFill } from 'react-icons/ri'

const Navigation = () => {
  const [hamburgerOpen, setHamburger] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [first, setFirst] = useState(1)

  const handleHamburger = () => {
    setHamburger(!hamburgerOpen)
    setMobileMenu(!mobileMenu)
    setFirst(0)
    if (!hamburgerOpen) document.querySelector('body').classList.add('noscroll')
    else document.querySelector('body').classList.remove('noscroll')
  }
  return (
    <section className="pheader">
      <div className="pheader__wrapper">
        <div className="pheader__content container">
          <nav className="">
            <a className="logo" href="/">
              Mon Pain Quotidien
            </a>
            <div
              className={
                hamburgerOpen
                  ? 'hamburger hide-for-desktop open'
                  : 'hamburger hide-for-desktop'
              }
              onClick={handleHamburger}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="main_menu hide-for-mobile">
              <a href="/#">Acceuil</a>
              <a href="/#features">Fonctionnalités</a>
              <a href="/#contact">Contact</a>
            </div>
            <div className="pheader__params hide-for-mobile">
              <span>
                <a href="/settings">
                  Conditions d'utilisation
                </a>
              </span>
              <span>
                <a href="/politics">
                  Politiques de confidentialités
                </a>
              </span>
            </div>
          </nav>
        </div>
        <div
          id="mobile_menu"
          className={
            mobileMenu
              ? 'hide-for-desktop mobile_menu show scroll-in'
              : !first
              ? 'hide-for-desktop mobile_menu scroll-out'
              : 'hide-for-desktop mobile_menu'
          }
        >
          <div className="wrapper">
            <div className="content">
              <div className="top">
                <div className="menu">
                  <a href="/#">Accueil</a>
                  <a href="/#features">Fonctionnalités</a>
                  <a href="/#contact">Contact</a>
                </div>
              </div>
              <div className="bottom">
                <div className="params">
                  <span>
                    <a href="/settings">
                      <AiFillSetting />
                      <span>Conditions d'utilisation</span>
                    </a>
                  </span>
                  <span>
                    <a href="/politics">
                      <RiGitRepositoryPrivateFill />
                      <span>Politiques de confidentialités</span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Navigation
