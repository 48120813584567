import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation";
import Home from "./pages/home";
import Notfound from "./pages/Notfound";
import Politics from "./pages/Politics";
import Settings from "./pages/Termofuse";


function App() {
  return (
    <Router>
      <>
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/settings" component={Settings} />
          <Route extact path="/politics" component={Politics} />
          <Route path="*" component={Notfound} />
        </Switch>
        {/* <Footer /> */}
      </>
    </Router>
  );
}

export default App;
