import React from 'react'
import { AiFillAndroid, AiFillApple } from 'react-icons/ai'

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__wrapper">
        <div className="hero__content container">
          <div className="row">
            <div className="col-md-6 col-sm-8 hero__illustration">
              <div className="illustration__wrapper">
                <img
                  className="img-fluid"
                  src="assets/images/daily-bread-app-3.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-8 texte__wrapper">
              <div className="hero__texte">
                <div className="d-flex texte_inner">
                  <h1>Mon Pain Quotidien</h1>
                  <p>
                    «Que ce livre de la loi ne s’éloigne point de ta bouche;
                    médite-le jour et nuit, pour agir fidèlement selon tout ce
                    qui y est écrit; car c’est alors que tu auras du succès dans
                    tes entreprises, c’est alors que tu réussiras.» <br />
                    ‭‭Josué‬ ‭1:8‬ ‭LSG‬‬
                  </p>
                  <div className="cta">
                    <div>
                      <a href="https://apps.apple.com/us/app/mon-pain-quotidien/id1589190440" className="btn" target="_blank" rel="noopener noreferrer">
                        <AiFillApple />
                        Apple Store
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.icc.monpainquotidien" className="btn" target="_blank" rel="noopener noreferrer">
                        <AiFillAndroid />
                        Play Store
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero__bottom">
          <div className="image__wrapper">
            <img src="assets/images/hero-bg-shape-1.svg" alt="shape" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
