import React from "react";
import Accordion from "../components/UI/Accordion";
import { politics } from "../Data/settingsItems";

const Politics = () => {
  return (
    <section className="politics">
      <div className="politics_wrapper">
        <div className="politics_content container">
          <div className="politics__title">
            <h2>Politique de Confidentialité Mon Pain Quotidien </h2>
            <p>
              <em>
                Cette politique de cookies a été mise à jour pour la dernière
                fois le 13 octobre 2021 et s’applique aux citoyens de l’Espace
                Économique Européen.
              </em>
            </p>
            <p>
              En accédant à Mon Pain Quotidien, vous acceptez cette politique de
              confidentialité ainsi que ses conditions et consentez à ce que vos
              données soient transférées et traitées en France. Votre
              utilisation de Mon Pain Quotidien est aussi régie par nos
              conditions d'utilisation. Veuillez lire ces conditions tout aussi
              attentivement.
            </p>
          </div>
          <div className="accordion__section">
            {politics.map((el, idx) => {
              return (
                <Accordion key={idx} title={el.title} content={el.content} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Politics;
