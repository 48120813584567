import React from 'react'

const Footer = () => {
  return (
    <section className="footer">
      <div className="f_bottom">
        <img
          className="img-fluid"
          src="assets/images/footer-top-shape.png"
          alt="bg"
        />
      </div>
      <p className="container px py">
        Copyrights &copy; Mon Pain Quotidien @2022
      </p>
    </section>
  )
}

export default Footer
