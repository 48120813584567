import React from 'react'

const Notfound = () => {
	return (
		<section className="notfound">
			
		</section>
	)
}

export default Notfound

