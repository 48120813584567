const settings = [
  {
    title: "Acceptation de ces conditions",
    content:
      "En utilisant les sites, services et applications mobiles de Mon Pain Quotidien, en tant qu'invité ou utilisateur abonné, vous acceptez et consentez à être lié par ces conditions. Veuillez lire attentivement ces conditions avant de commencer à utiliser Mon Pain Quotidien, ainsi que les politiques de confidentialité. Si vous n'acceptez pas ces conditions ou la politique de confidentialité de Mon Pain Quotidien, vous ne devez pas accéder ou utiliser Mon Pain Quotidien.",
  },
  {
    title: "Changements des conditions d'utilisation",
    content:
      "Mon Pain Quotidien est mis à disposition par Impact Centre Chrétien : Auditorium ICC - 20, rue des sablons, Z.A. La Haie Griselle – 94470 Boissy Saint Léger. Nous pouvons réviser et mettre à jour ces conditions de temps en temps à notre seule et unique convenance. Toutes modifications prennent effet lorsque nous les publions. Votre utilisation de Mon Pain Quotidien suite à la publication de conditions d'utilisations revues signifie que vous acceptez les changements apportés.",
  },
  {
    title: "Changements chez Mon Pain Quotidien",
    content:
      "Bien que nous espérons pouvoir toujours vous offrir Mon Pain Quotidien et tous ses services, nous nous réservons le droit de modifier ou de cesser d'offrir Mon Pain Quotidien ou toute partie, portion ou fonctionnalité de Mon Pain Quotidien sans préavis. Nous ne saurions être tenus responsable si, pour une raison quelconque, tout ou une partie de Mon Pain Quotidien était indisponible à tout moment.",
  },
  {
    title:
      "Informations vous concernant et sur votre accès à Mon Pain Quotidien",
    content:
      "Toutes les informations collectées sur ou par Mon Pain Quotidien sont soumises à notre politique de confidentialité. En utilisant Mon Pain Quotidien, vous acceptez toutes les mesures prises conformément au respect de la politique de confidentialité.",
  },
  {
    title: "Utilisation autorisée et non autorisée",
    content:
      "Vous pouvez utiliser Mon Pain Quotidien conformément aux présentes conditions, soit pour votre usage personnel et non commercial ou pour l'usage interne de votre organisation religieuse à but non lucratif. Tous les droits qui ne vous sont pas expressément accordés sont réservés par Impact Centre Chrétien. Vous acceptez que vous n'êtes pas autorisés et n'utiliserez pas Mon Pain Quotidien  comme suit:" +
      "<ul>" +
      "<li>de quelque manière que ce soit qui viole toute loi, tout règlement ou toute pratique ou directive généralement acceptée dans les juridictions concernées (y compris toutes lois concernant l’exportation de données ou de logiciels en provenance ou à destination des France ou d'autres pays le cas échéant).</li >" +
      "<li>dans le but d'exploiter, de nuire à, ou d'essayer d'exploiter ou de nuire à, un mineur de quelque manière que ce soit en lui exposant un contenu inapproprié, en demandant des informations personnelles identifiable ou d'un autre manière;</li>" +
      "<li>pour usurper l'identité ou tenter d'usurper l'identité d'une entreprise ou d'une personne, y compris d’Impact Centre Chrétien ou d'un autre utilisateur de Mon Pain Quotidien;</li>" +
      "<li>pour vous livrer à toute autre conduite qui, telle que déterminée par nous, peut nuire à Impact Centre Chrétien ou aux utilisateurs de Mon Pain Quotidien, ou engager leur responsabilité;</li > " +
      "<li>de quelque manière que ce soit qui pourrait désactiver, surcharger, endommager ou altérer Mon Pain Quotidien ou interférer avec l'utilisation de Mon Pain Quotidien par un tiers;</li>" +
      "<li>en utilisant un robot, ou tout autre dispositif, processus ou moyen d'accéder à Mon Pain Quotidien dans n'importe quel but y compris la surveillance ou la copie de n'importe quelle ressource sur Mon Pain Quotidien;</li>" +
      "<li>pour introduire des virus, chevaux de Troie, vers informatiques, bombes logiques, ou tout autre contenu malveillant ou technologiquement nuisible;</li>" +
      "<li>pour tenter d'accéder, d'interférer, d'endommager ou de perturber n'importe quelle partie de Mon Pain Quotidien ou n'importe quel serveur, ordinateur, ou base de données connectée à Mon Pain Quotidien;</li>" +
      "<li>pour attaquer Mon Pain Quotidien par le biais d'une attaque par déni de service (DoS attack) ou d'une attaque par déni de service distribuée; et</li>" +
      "<li>ou encore toute tentative d’interférer avec le bon fonctionnement de Mon Pain Quotidien.</li > " +
      "</ul >" +
      "Vous acceptez également de ne pas envoyer, recevoir, charger, télécharger, utiliser ou réutiliser sciemment tout matériel qui:" +
      "<ul>" +
      "<li>contient du contenu diffamatoire, obscène, indécent, abusif, offensant, harcelant, violent, haineux, provoquant ou autrement répréhensible;</li>" +
      "<li>promeut du contenu sexuellement explicite ou pornographique, la violence, ou la discrimination, y compris celle basée sur la race, le sexe, la religion, la nationalité, le handicap, l'orientation sexuelle, ou l'âge;</li>" +
      "<li>enfreint tout brevet, marque déposée, secret commercial, droit d'auteur, ou toute autre propriété intellectuelle ou droits d'une personne tierce;</li>" +
      "<li>viole les droits légaux (y compris les droits de publicité ou de confidentialité) d'autrui ou contient du contenu qui pourrait donner lieu à une responsabilité civile ou criminelle en vertu des lois ou règlements applicables;</li>" +
      "<li>promeut toute activité illégale, ou défend, promeut, ou assiste tout acte illégal;</li>" +
      "<li>implique des activités commerciales ou des ventes, telles que des concours, des tirages au sort et d'autres promotions des ventes, le troc ou la publicité; et/ou</li>" +
      "<li>donne l'impression que le contenu provient de ou est approuvé par Impact Centre Chrétien ou toute autre personne ou entité, alors que ce n'est pas le cas.</li>" +
      "</ul>",
  },
  {
    title:
      "Surveillance et application de la loi; Conformité légale; Résiliation",
    content:
      "<span>Vous acceptez que nous avons le droit de:</span>" +
      "<ul>" +
      "<li>prendre des mesures à l’égard de tout utilisateur, ou de la Contribution de l’utilisateur que nous jugeons nécessaire ou approprié à notre entière discrétion, si nous croyons qu’il existe une violation de ces termes, une infraction aux droits de tiers, une menace pour la sécurité personnelle des autres , ou une menace, responsabilité ou dommage à la bonne volonté associée à Mon Pain Quotidien ou Impact Centre Chrétien;</li>" +
      "<li>prendre les mesures appropriées, y compris et sans limitation, le renvoi à l'application de la loi et la divulgation de vos informations, pour toute utilisation illégale ou non autorisée de Mon Pain Quotidien; et/ou</li > " +
      "<li>résilier ou suspendre votre accès à tout ou une partie de Mon Pain Quotidien pour n'importe quelle raison, y compris, sans s'y limiter, toute violation de ces conditions.</li>" +
      "</ul>" +
      "<span>Sans limiter ce qui précède, nous avons le droit de coopérer pleinement avec les autorités légales chargées de l'application de la loi ou une ordonnance du tribunal nous demandant ou nous ordonnant de divulguer l'identité ou d'autres informations de toute personne publiant des documents sur Mon Pain Quotidien. VOUS RENONCEZ ET EXONÉREZ IMPACT CENTRE CHRETIEN ET SES LICENCIÉS ET FOURNISSEURS DE SERVICES ET TOUS LEURS EMPLOYÉS, DIRIGEANTS ET ADMINISTRATEURS DE TOUTE RÉCLAMATION RÉSULTANT DE TOUTES MESURES PRISES PAR L'UNE DES PARTIES PRÉCÉDENTES PENDANT OU À LA SUITE D'ENQUÊTES JUDICIAIRES ET DE TOUTES MESURES PRISES COMME CONSÉQUENCE D'ENQUÊTES PAR IMPACT CENTRE CHRETIEN LILLE OU PAR LES AUTORITÉS EN CHARGE DE L'APPLICATION DE LA LOI.</span>",
  },
  {
    title: "Votre dépendance à l'égard de l'information",
    content:
      "Les informations présentées sur ou via Mon Pain Quotidien sont mises à disposition uniquement à des fins d'information générale. Nous ne garantissons pas l'exactitude, l'exhaustivité ou l'utilité de ces informations. Toute confiance que vous accordez à ces informations est strictement à vos propres risques. Nous déclinons toute responsabilité découlant de la confiance que vous, tout autre visiteur sur Mon Pain Quotidien, ou toute personne qui pourrait être informée de son contenu, accordez à ces informations.",
  },
  {
    title: "Droit de la propriété intellectuelle",
    content:
      "Mon Pain Quotidien et son contenu, ses caractéristiques et ses fonctionnalités (y compris mais non limité à toutes les informations, logiciels, textes, affichages, images, vidéos et audios, et la conception, la sélection et l'arrangement de l'application) sont la propriété d’Impact Centre Chrétien ses donneurs de licence ou d'autres fournisseurs d'un tel matériaux, et sont protégés par les lois française et internationales sur le droit d'auteur, les marques de commerce, les brevets, les secrets commerciaux et d'autres lois sur la propriété intellectuelle ou les droits de propriété.",
  },
  {
    title: "Logos et designs",
    content:
      "Le nom et le logo de Mon Pain Quotidien, le logo  et tous les noms des produits et des services, designs et slogans qui s'y rapportent sont des marques et/ou des droits d'auteur d’Impact Centre Chrétien. Vous ne devez pas utiliser ces marques et droits d'auteur sans l'autorisation écrite préalable d’Impact Centre Chrétien. Tous les autres noms, logos, noms de produits et de services, designs et slogans sur Mon Pain Quotidien sont des marques commerciales de leurs propriétaires respectifs.",
  },
  {
    title: "Liens et services de tiers",
    content:
      "Mon Pain Quotidien peut inclure du contenu fourni par des tiers. Si Mon Pain Quotidien contient des liens vers d'autres sites, des pop-ups et des ressources similaires fournies par des tiers, ces liens et ressources sont fournis uniquement pour votre commodité. Nous n'avons aucun contrôle sur le contenu de ces sites ou ressources, et n'acceptons aucune responsabilité pour eux ou pour toute perte ou dommage pouvant découler de votre utilisation de ceux-ci. Si vous décidez d'accéder à l'un des sites Web tiers liés à Mon Pain Quotidien, vous le faites entièrement à vos risques et périls et sous réserve des termes et conditions d'utilisation de ces sites Web. Toutes les déclarations et / ou opinions exprimées dans ces documents, et tous les articles et réponses aux questions et autres contenus, autres que le contenu fourni par Impact Centre Chrétien, sont uniquement les opinions et la responsabilité de la personne ou de l'entité fournissant ces documents. Ces documents ne reflètent pas nécessairement l'opinion de Impact Centre Chrétien. Nous ne sommes pas responsables envers vous ou un tiers, du contenu ou de l'exactitude de tout matériel fourni par des tiers.",
  },
  {
    title: "Restrictions géographiques",
    content:
      "Impact Centre Chrétien, le propriétaire de Mon Pain Quotidien, est basé à Boissy Saint Léger en France. Nous ne prétendons aucunement que Mon Pain Quotidien ou l'un de ses contenus soit accessible ou approprié en dehors de la France. L'accès à Mon Pain Quotidien peut être illégal pour certaines personnes ou dans certains pays. Si vous accédez à Mon Pain Quotidien depuis l'extérieur de la France c'est de votre propre initiative et vous êtes responsable face au respect des lois locales.",
  },
  {
    title: "Exclusion de garanties",
    content:
      "<span>VOTRE UTILISATION DE Mon Pain Quotidien, DE SON CONTENU ET DE TOUTE FONCTIONNALITÉS, SERVICES OU ARTICLES OBTENUS À TRAVERS Mon Pain Quotidien EST À VOS PROPRES RISQUES, TOUS CEUX QUI SONT FOURNIS SOUS UN STATUT \"TEL QUEL\" OU \"DISPONIBLE\", SANS AUCUNE SORTE DE GARANTIE, SOIT IMPLICITE SOIT EXPLICITE. NI IMPACT CENTRE CHRÉTIEN NI AUCUNE PERSONNE ASSOCIÉE AVEC IMPACT CENTRE CHRÉTIEN  NE GARANTIE NI NE REPRÉSENTE L'EXHAUSTIVITÉ, LA SÉCURITÉ, LA FIABILITÉ, LA QUALITÉ, L'EXACTITUDE OU LA DISPONIBILITÉ DE Mon Pain Quotidien. SANS LIMITER CE QUI A ÉTÉ PRÉCITÉ NI IMPACT CENTRE CHRÉTIEN  NI AUCUNE PERSONNE ASSOCIÉE AVEC IMPACT CENTRE CHRÉTIEN NE REPRÉSENTE NI NE GARANTIE QUE MON PAIN QUOTIDIEN OU N'IMPORTE QUEL CONTENU DES FONCTIONNALITÉS OU N'IMPORTE QUEL SERVICE OU ARTICLE OBTENU PAR  Mon Pain Quotidien SOIT EXACT, JUSTE, ININTERROMPU, QUE LES DEFAUTS SERONT CORRIGES, QUE  Mon Pain Quotidien OU LE SERVEUR QUI LE REND DISPONIBLE SOIT VIERGE DE TOUT VIRUS OU D'AUTRES COMPOSANTS NUISIBLES OU QUE  MON PAIN QUOTIDIEN OU N'IMPORTE LEQUEL DE CES SERVICES OU ARTICLE OBTENUS À TRAVERS  MON PAIN QUOTIDIEN POURRA AUTREMENT RÉPONDRE À VOS BESOINS OU VOS ATTENTES.</span>" +
      "<span>IMPACT CENTRE CHRÉTIEN DÉCLINE ICI TOUTE GARANTIE D'AUCUNE SORTE SOIT IMPLICITE SOIT EXPLICITE, LEGALE OU AUTRE, INCLUANT MAIS NE LIMITANT PAS LES GARANTIES DE QUALITÉ MARCHANDE, D'ABSENCE DE CONTREFAÇON ET D'ADAPTATION À UN USAGE PARTICULIER.</span>" +
      "<span>CE QUI PRÉCÈDE N'AFFECTE AUCUNE GARANTIE QUI NE PUISSE ÊTRE EXCLUE OU LIMITÉE EN VERTU DE LA LOI APPLICABLE.</span>",
  },
  {
    title: "Responsabilité limitée",
    content:
      "<span>EN AUCUN CAS IMPACT CENTRE CHRÉTIEN, SES CONCÉDANTS, FOURNISSEURS DE SERVICES OU AUCUN DE SES EMPLOYÉS, AGENTS, DIRIGEANTS OU ADMINISTRATEURS NE POURRONT ÊTRE TENUS RESPONSABLES DES DOMMAGES DE QUELQUE NATURE QUE CE SOIT, SOUS UNE THÉORIE JURIDIQUE, DÉCOULANT OU EN RAPPORT AVEC VOTRE UTILISATION OU VOTRE INCAPACITÉ À UTILISER, MON PAIN QUOTIDIEN, TOUS LES SITES WEB LIÉS, TOUT CONTENU OFFERT SUR OU PAR L'INTERMÉDIAIRE DE Mon Pain Quotidien, OU D'AUTRES SITES WEB OU TOUT AUTRE SERVICE OU ARTICLE OBTENU PAR LE BIAIS DE MON PAIN QUOTIDIEN, Y COMPRIS LES DOMMAGES DIRECTS, INDIRECTS, SPÉCIAUX, ACCESSOIRES OU PUNITIFS INCLUANT MAIS NON LIMITÉ À, BLESSURE PERSONNELLE, DOULEUR ET SOUFFRANCE, DÉTRESSE ÉMOTIONNELLE, PERTE DE REVENUS, PERTE DE PROFITS, PERTE D'AFFAIRES OU D'ÉCONOMIES PRÉVUES, PERTE D'UTILISATION, PERTE D'ÉCARTS D'ACQUISITION, PERTE DE DONNÉES ET MÊME CAUSÉE PAR DÉTOURNEMENT (Y COMPRIS LA NÉGLIGENCE), INFRACTION DE CONTRAT OU AUTRE, MÊME SI MÊME PRÉVISIBLE.</span>" +
      "<span>CE QUI PRÉCÈDE N'AFFECTE AUCUNE RESPONSABILITÉ QUI NE PUISSE ÊTRE EXCLUE OU LIMITÉE EN VERTU DE LA LOI APPLICABLE.<span>",
  },
  {
    title: "Indemnisation",
    content:
      "Vous acceptez de défendre, indemniser et tenir pour innocent Impact Centre Chrétien, ses affiliés, détenteurs de licences et prestataires de services, et leurs dirigeants, directeurs, employés, sous-traitants, agents, fournisseurs de licences, fournisseurs, successeurs et ayants droits respectifs, de et contre toute réclamation, responsabilité, dommage, jugements, sentence, pertes, coûts, frais ou honoraires (y compris les honoraires raisonnables d'avocats) émanant de ou liés à votre non respect des présentes Conditions d'Utilisation ou votre utilisation de Mon Pain Quotidien, y compris, mais non limité à, vos contributions d'utilisation, toute utilisation de contenu Mon Pain Quotidien, services et produits autrement que comme expressément autorisé dans les présentes conditions d'utilisation ou votre utilisation de toute information obtenue de Mon Pain Quotidien.",
  },
  {
    title: "Loi applicable et juridiction",
    content:
      "Les présentes Conditions et vos droits et obligations envers Mon Pain Quotidien tels que prévus aux présentes seront régis par le droit français. Mon Pain Quotidien et vous-même acceptez de soumettre tout litige découlant des présentes Conditions à la compétence exclusive des Tribunaux français. Nonobstant ce qui précède, Mon Pain Quotidien sera en droit de solliciter l'octroi de toutes mesures conservatoires, injonctions ou toute autre mesure d’urgence devant toute juridiction compétente.",
  },
  {
    title: "Renonciation et divisibilité",
    content:
      "Impact Centre Chrétien ne peut renoncer à ses droits en vertu des présentes conditions d'utilisation que par écrit. Si une des clauses de ces conditions d'utilisation est jugée, par un tribunal ou autre instance compétente, comme étant invalide, illégale, ou inapplicable, pour une quelconque raison, une telle clause devra être éliminée ou limitée à une mesure minimale de telle sorte que les clauses restantes de ces conditions d'utilisation puissent être pleinement appliquées.",
  },
  {
    title: "Accord complet",
    content:
      "Ces conditions d'utilisation et notre politique de confidentialité constituent le seul et unique accord entre vous et Impact Centre Chrétien, concernant Mon Pain Quotidien et remplace tous les accords, déclarations et garanties, écrits ou oraux, précédents et courants concernant Mon Pain Quotidien.",
  },
  {
    title: "Vos commentaires et préoccupations",
    content:
      'Mon Pain Quotidien est géré par Impact Centre Chrétien, Association N° W941003523 déclarée à la préfecture du Val-de-Marne Journal Officiel du 01 décembre 2001  N° 2050. Toutes les réactions, commentaires, demandes d\'assistance technique et autres communications relatives à Mon Pain Quotidien doivent être adressées à Impact centre chrétien Lille, 3 Allée des Prairies  59493 Villeneuve-d’Ascq FRANCE ou contact.<a href="mailto:contact@monpainquotidien.com">contact@monpainquotidien.com</a>.',
  },
];

const politics = [
  {
    title: "Les informations personnelles que vous nous fournissez.",
    content:
      "Vous n'avez pas besoin de créer un compte de membre pour utiliser Mon Pain Quotidien. Aucune information personnelle n’est collectée pour l’utilisation quotidienne de l’application. Nous recueillons uniquement des informations sur vous lorsque vous envoyez, recevez ou échangez des messages avec nous, y compris lorsque vous soumettez des informations personnelles ou des demandes par courrier électronique à contact@monpainquotidien.com. Nous conservons ces communications pour traiter vos requêtes, répondre à vos demandes et améliorer Mon Pain Quotidien et nos services. ",
  },
  {
    title: "Comment nous utilisons vos données",
    content:
      "Concernant les autres données transmises, nous recueillons vos contributions: écrits, commentaires, réponses en lien avec les études bibliques. Ces données sont conservées dans le cache de votre téléphone portable.  Nous utilisons les données que nous recueillons de vous pour: <br/><br />" +
      "<ul>" +
      "<li>Vous permettre d'accéder, de réaccéder, de publier et d'utiliser vos contributions d'utilisateur comme vous le souhaitez.</li>" +
      "<li>Traiter vos demandes et répondre à vos requêtes;</li>" +
      "<li>Maintenir la sûreté, la sécurité et l'intégrité de  Mon Pain Quotidien  et de l'infrastructure qui facilite l'utilisation de Mon Pain Quotidien ;</li>" +
      "<li>Une analyse interne concernant l'utilisation de  Mon Pain Quotidien ;</li>" +
      "<li>Pour répondre à tout autre objectif pour lequel vous les fournissez;</li>" +
      "<li>Pour nous acquitter de nos obligations et faire valoir nos droits en vertu de la loi applicable, y compris nos droits et obligations en vertu de la présente politique de confidentialité et nos conditions d'utilisation;</li>" +
      "</ul>",
  },
  {
    title:
      "Vos droits de suppression, d'accès et de correction de vos informations",
    content:
      "<b>Comment créer vos demandes.<b><br/><br/>" +
      "<p>Pour les données personnelles que nous avons sur vous, vous pouvez demander ce qui suit:</p>" +
      "<ul>" +
      "<li>Suppression: Vous pouvez nous demander d'effacer ou de supprimer toutes ou une partie de vos données personnelles.</li>" +
      "<li>S'opposer à, limiter ou restreindre l'utilisation des données: Vous pouvez nous demander de cesser d'utiliser tout ou une partie de vos données personnelles ou d'en limiter l'utilisation.</li>" +
      "<li>Droit d'accès et/ou de modifier de vos données: Vous pouvez nous demander une copie ou une divulgation des données à caractère personnel que vous nous avez fournies.</li>" +
      "</ul>" +
      "<p> Certaines lois peuvent prévoir le droit de faire cela et d'introduire d'autres demandes supplémentaires concernant vos informations personnelles. Si vous nous avez fourni des informations personnelles et que vous souhaitez faire une telle demande en vertu de la loi d'une région spécifique, ou toute autre demande concernant vos informations personnelles, vous pouvez envoyer un courrier électronique  contact@monpainquotidien.com ou envoyez votre demande à : Impact Centre Chrétien Lille, 3 Allée des Prairies 59493 Villeneuve-d’Ascq FRANCE.</p>" +
      "<p>Nous demandons aux personnes qui font des demandes de s'identifier avec au moins leur nom, adresse, courrier électronique et d'identifier les informations dont l'accès, la correction ou la suppression est demandé avant de traiter toute demande. Nous pouvons refuser de traiter les demandes si nous ne pouvons pas vérifier l'identité du demandeur, si nous pensons que la demande mettra en danger la vie privée d'autres personnes, si nous pensons que la demande violerait une loi ou une exigence légale, si nous pensons que la demande rendrait les informations incorrectes, ou pour un objectif légitime similaire.</p>" +
      "<p>Nous n'exercerons aucune discrimination à votre encontre dans l'exercice de vos droits en vertu de la loi en vigueur. Nous ne facturons pas de frais pour traiter ou répondre à votre demande vérifiable, sauf si celle-ci est excessive, répétitive ou manifestement non fondée. Si nous estimons que la demande nécessite des frais, nous vous indiquerons les raisons de cette décision et vous fournirons une estimation des coûts avant de traiter votre demande.</p><br/><br/>" +
      "<b>Sécurité et protection</b><br/><br/>" +
      "<p>Nous utilisons les mesures de sécurité standard conçues pour protéger vos données. Cela inclut l'utilisation du cryptage pour vos données pendant leur transmission entre votre appareil ou votre navigateur et nos serveurs et aussi lorsqu'elles sont au repos. Les données fournies par Mon Pain Quotidien sont également stockées dans un système de gestion d'infrastructure certifié ISO 27017, ce qui signifie qu'il a été jugé conforme aux normes de système de management ISO 27017, un code de pratique internationalement reconnu pour les contrôles de sécurité de l'information pour les services de cloud.</p>" +
      "<p>Cependant, étant donné la nature de la technologie de la communication et de l'information, et puisque l'utilisation d'Internet comporte des risques inhérents, bien que nous surveillons régulièrement les vulnérabilités et les attaques possibles, nous ne pouvons assurer ou garantir que ces informations fournies par Mon Pain Quotidien ou stockées dans nos systèmes seront totalement exempts d'intrusion extérieur non autorisée, et nous ne pouvons assurer ou garantir que ces données ne peuvent être consultées, divulguées, altérées ou détruites par une violation de nos garanties physiques, techniques ou de gestion.</p><br/><br/>" +
      "<b>Enfants de moins de 16 ans</b><br/><br/>" +
      "<p>Nous ne recueillons pas d'informations personnelles sur une personne dont nous savons qu'elle a moins de 16 ans sans le consentement du parent ou du tuteur légal de ce mineur.</p><br/><br/>" +
      "<b>Informations de contact</b><br/><br />" +
      "Mon Pain Quotidien est géré par Impact Centre Chrétien, Association N° W941003523 déclarée à la préfecture du Val-de-Marne Journal Officiel du 01 décembre 2001  N° 2050." +
      "Pour poser des questions ou faire des commentaires sur la présente politique de confidentialité et sur nos pratiques en matière de protection de la vie privée, vous pouvez nous contacter à l'adresse suivante Impact Centre Chrétien Lille, 3 Allée des Prairies  59493 Villeneuve-d’Ascq FRANCE ; ou à contact@monpainquotidien.com",
  },
];

export { settings, politics };
