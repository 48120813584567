import React from 'react'

import Accordion from '../components/UI/Accordion'
import { settings } from '../Data/settingsItems'
const Settings = () => {
  return (
    <section className="settings">
      <div className="settings_wrapper">
        <div className="settings_content container">
          <div className="settings__title">
            <h2>Conditions d'utilisation</h2>
          </div>
          <div className="accordion__section">
            {settings.map((el, idx) => {
              return (
                <Accordion key={idx} title={el.title} content={el.content} />
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Settings
