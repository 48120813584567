import React from "react";

import Hero from "../components/Hero";
import Featuresv2 from "../components/Featuresv2";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <Hero />
      <Featuresv2 />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
